<template>
  <v-row>
    <v-col cols="12" md="6" lg="2">
      <v-select
        clearable
        v-model="params.status"
        :items="['created', 'processing', 'completed', 'failed', 'cancelled']"
        label="Status"
        outlined
        hide-details
        dense
        @change="$emit('onSearch')"
      ></v-select>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: ['params', 'options'],
}
</script>
<style lang=""></style>
