import request from '@/services/request'
export function index(params) {
  return request({
    url: '/tasks',
    method: 'get',
    params,
  })
}

export function cancel(id) {
  return request({
    url: `/tasks/${id}/cancel`,
    method: 'post',
  })
}

export function requeue(id) {
  return request({
    url: `/tasks/${id}/requeue`,
    method: 'post',
  })
}
