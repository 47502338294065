<template>
  <v-data-table
    :style="{ height }"
    height="calc(100% - 48px)"
    :headers="getHeaders()"
    :items="tableData"
    disable-pagination
    fixed-header
    :options.sync="sortOptions"
    calculate-widths
    hide-default-footer
    :server-items-length="1"
    disable-filtering
  >
    <template v-slot:top>
      <v-toolbar dense flat>
        <v-dialog v-model="showInformationDialog" persistent max-width="350">
          <v-card v-if="selectedTask">
            <v-card-title>Task: {{ selectedTask.name }}</v-card-title>
            <v-card-text>{{ selectedTask | information }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="showInformationDialog = false"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-text-field
          v-model="params.search"
          style="max-width: 300px"
          label="Search"
          clearable
          hide-details
          dense
          :append-icon="icons.mdiMagnify"
          single-line
          @keyup.enter="$emit('on-search')"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-btn icon color="primary" @click="$emit('refreshed')">
          <v-icon>{{ icons.mdiRefresh }}</v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.createdAt="{ item }">
      <span>{{ item.createdAt | datetime }}</span>
    </template>
    <template v-slot:item.finishedAt="{ item }">
      <span>{{ item.finishedAt | datetime }}</span>
    </template>
    <template v-slot:item.type="{ item }">
      <span>Upload {{ item.resultType || 'CDS' }}</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        :disabled="item.status !== 'failed'"
        title="Retry task"
        @click="requeue(item.id)"
        x-small
        text
        fab
        color="primary"
      >
        <v-icon>{{ icons.mdiRefresh }}</v-icon>
      </v-btn>
      <v-btn
        :disabled="item.status !== 'processing'"
        title="Cancel task"
        @click="cancel(item.id)"
        class="ml-2"
        text
        x-small
        fab
        color="warning"
      >
        <v-icon>{{ icons.mdiCancel }}</v-icon>
      </v-btn>
      <v-btn title="Show information" @click="showInformation(item)" class="ml-2" text x-small fab color="info">
        <v-icon>{{ icons.mdiInformation }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip v-if="item.status === 'created'" color="info">Created</v-chip>
      <v-chip v-if="item.status === 'processing'" color="primary">Processing</v-chip>
      <v-chip v-if="item.status === 'completed'" color="success">Completed</v-chip>
      <v-chip v-if="item.status === 'failed'" color="error">Failed</v-chip>
      <v-chip v-if="item.status === 'cancelled'" color="info">Cancelled</v-chip>
    </template>
    <template v-slot:item.size="{ item }"> {{ item.size | size }} </template>
  </v-data-table>
</template>
<script>
import dataTableMixin from '@/mixins/crud/data-table'
import { mdiImage, mdiVectorRectangle, mdiCancel, mdiInformation } from '@mdi/js'
import { requeue, cancel } from '@/api/task'
export default {
  mixins: [dataTableMixin()],

  data() {
    return {
      icons: { mdiImage, mdiVectorRectangle, mdiCancel, mdiInformation },
      showInformationDialog: false,
      selectedTask: null,
    }
  },

  filters: {
    information(task) {
      switch (task.status) {
        case 'created':
          return 'Task created successfully'
        case 'processing':
          return 'Task is processing'
        case 'completed':
          return 'Task completed successfully'
        case 'failed':
          return task.error
        case 'cancelled':
          return 'Task cancelled'
      }
    },
  },

  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'type' },
        { text: 'Status', value: 'status' },
        { text: 'Created At', value: 'createdAt' },
        { text: 'Finished At', value: 'finishedAt' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false },
      ]
    },
  },
  methods: {
    async requeue(id) {
      try {
        await this.$confirm('Are you sure when requeuing this task?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
        this.$loader(true)
        await requeue(id)
        this.$message('Requeue Successfully', 'success')
        this.$emit('deleted')
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },
    async cancel(id) {
      try {
        await this.$confirm('Are you sure when cancelling this task?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
        this.$loader(true)
        await cancel(id)
        this.$message('Cancel Successfully', 'success')
        this.$emit('deleted')
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },
    async showInformation(task) {
      this.selectedTask = task
      this.showInformationDialog = true
    },
  },
}
</script>
